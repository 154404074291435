:root {
    --primary-color: #98348c;
    --primary-color-dark: #7a196f;
    --secondary-color: #65a0f1;
    --secondary-color-dark: #4990f3;
    --secondary-color-darker: #2b3f5f;
    --gradient-from: rgb(245, 36, 229, 0.8);
    --gradient-to: rgba(80, 162, 248, 0.8);
    --gradient-degree: 45deg;
    --blue-background-color: #143767;
    --blue-background-color: #65a0f1;
    --light-blue-background-color: #88baff;
    --gray-background-color: #fafafa;
    --white-background-color: #ffffff;
    --headline: #18191a;
    --caption: #c2c7cc;
    --text-light-gray: #616366;
    --text-gray: #919599;
    --text-dark: #3d3e40;
    --gray: #9b9b9b;
    --gray-dark: #6d6d6d;
    --red: #dc3545;
    --green: #35dc80;
    --red-dark: #ff5f6f;
    --yellow: #dfaa0c;
    --navbar-height: 70px;
    --border-radius-small: 2px;
    --border-radius-large: 8px;
    --border-radius-huge: 30px;
    --font-poppins: 'Poppins', sans-serif;
    --font-open-sans: 'Open Sans', sans-serif;
    --font-inter: 'Inter', sans-serif;
}

* {
    box-sizing: border-box;
    font-family: var(--font-open-sans);
    font-size: 18px;
}

body {
    margin: 0;
    background-color: var(--gray-background-color);
}

a {
    color: var(--secondary-color);
    text-decoration: none;
}

.light-text {
    color: #4e4e4e;
}

section {
    width: 92%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 50px 0;
}

p,
h1,
h2,
h3,
h4,
h5 {
    overflow-wrap: break-word;
}

section.no-padding-top {
    padding-top: 1px;
}

li {
    margin: 17.5px 0;
}

h1,
h2 {
    font-weight: 400;
}

h1 {
    font-size: 30px;
    text-align: center;
}

h1.large {
    font-size: 40px;
    font-weight: 600;
    text-align: initial;
}

h2 {
    font-size: 25px;
}

h2.large {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
}

h2.extra-large {
    font-size: 40px;
    font-weight: 600;
}

h2.with-margin {
    margin-top: 100px;
}

h3 {
    font-size: 20px;
}

p.large-sub-text {
    font-size: 30px;
    color: #5a5e62;
}

p.sub-text {
    font-size: 20px;
    color: #5a5e62;
}

p.small-sub-text {
    font-weight: 600;
    font-size: 15px;
    color: #5a5e62;
}

p.extra-small-sub-text {
    font-weight: 600;
    font-size: 13px;
    color: #5a5e62;
}

.highlight-text {
    font-weight: 700;
    font-size: 20px;
}

/* Subtitle */
h4 {
    text-transform: uppercase;
    font-size: 20px;
    color: var(--primary-color);
    text-align: center;
}

.large {
    font-size: 2.2em;
    margin: 20px 0;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.sub-text {
    color: #c2c7cc;
    font-weight: 600;
}

.small-sub-text {
    font-size: 12px;
    color: #c2c7cc;
    font-weight: 600;
}

.no-margin {
    margin: 0px;
}

.no-padding {
    padding: 1px;
}

.add-blue-color,
p.add-blue-color {
    color: #65a0f1;
}

.add-white-color {
    color: #ffffff;
}

.left,
h2.left {
    text-align: left;
}

.place-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 750px) {
    h1 {
        font-size: 33px;
    }

    h1.large {
        font-size: 35px;
    }

    h1,
    h2 {
        text-align: center;
    }

    header {
        padding-top: 70px;
    }

    section {
        padding: 40px 0;
    }

    .large {
        font-size: 1.5em;
    }
}
